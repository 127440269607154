import React from "react";
import CardDivider from "../share/CardDivider";
import "../styles/gradients.css";
import { Mission } from "../../services/missionService";
import { useDrawerContext } from "../../context/DrawerContext";
import useRegisterHeight from "../../hooks/useRegisterHeight";

interface LevelCardProps {
  mission: Mission;
}

const LevelCard: React.FC<LevelCardProps> = ({ mission }) => {
  const {
    currentCurrentLevelId: currentLevelId,
    setCurrentCurrentLevelId: setCurrentLevelId,
  } = useDrawerContext();
  const isActive = currentLevelId === mission.id && currentLevelId !== "NONE";
  const ref = useRegisterHeight("levelCard", isActive);

  const handleCardClick = () => {
    setCurrentLevelId(mission.id); // Définit la carte comme active
  };

  return (
    <div
      ref={ref}
      onClick={handleCardClick} // Remplace le lien par un gestionnaire d'événement
      className={`relative max-w-[430px] h-auto mx-auto bg-black overflow-hidden gradient-border transform transition-transform duration-300 ease-in-out ${
        // isActive ? "bg-secondary scale-105" : ""
        isActive ? "bg-secondary " : ""
      } cursor-pointer`} // Utilisation de scale-105 pour un léger zoom sur la carte active
    >
      <div className="relative flex flex-col p-4 gap-4">
        <div>
          <h2 className="font-bold text-3xl text-white text-left uppercase">
            {mission.title}
          </h2>
        </div>
        <CardDivider color="border-secondary" />
        <div className="flex justify-center">
          <img
            className="w-full h-48 object-cover"
            src={mission.image}
            alt={mission.title}
          />
        </div>
        <p className="text-2xl text-white text-left">
          {mission.shortDescription}
        </p>
      </div>
    </div>
  );
};

export default LevelCard;
