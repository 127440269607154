/* eslint-disable react/prop-types */
import { initializeYinYangApp } from "@micheeeeel/yinyangapp";
import { useEffect, useState } from "react";

interface YinYangVisualizationProps {
  size: number; // Permet de définir la taille du SVG pour chaque instance de marqueur
}

const YinYangVisualization: React.FC<YinYangVisualizationProps> = ({
  size,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const viewDataEnemy = {
      minEdge: size,
      containerId: "svg-container",
      borderAnimationDuration: 15000,
    };

    const timeoutId = setTimeout(() => {
      initializeYinYangApp(viewDataEnemy);
      setIsLoaded(true);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [size]);

  return (
    <div
      id="svg-container"
      className={`transition-opacity duration-700 ease-out ${
        isLoaded ? "opacity-100" : "opacity-0"
      }`}
      style={{ width: `${size}px`, height: `${size}px` }}
    ></div>
  );
};

export default YinYangVisualization;
