import React, { createContext, useContext, useState, useCallback } from "react";

interface HeightManagerContextProps {
  registerComponentHeight: (id: string, height: number) => void;
  unregisterComponentHeight: (id: string) => void;
  getComponentHeightById: (id: string) => number; // Nouvelle méthode
  totalHeight: number;
}

const HeightManagerContext = createContext<
  HeightManagerContextProps | undefined
>(undefined);

export const ComponentHeightProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [componentHeightsMap, setComponentHeightsMap] = useState<
    Record<string, number>
  >({});

  // Calculate the total height
  const totalHeight = Object.values(componentHeightsMap).reduce(
    (sum, height) => sum + height,
    0
  );

  // Register the height of a component
  const registerComponentHeight = useCallback((id: string, height: number) => {
    setComponentHeightsMap((prev) => ({ ...prev, [id]: height }));
  }, []);

  // Unregister the height of a component
  const unregisterComponentHeight = useCallback((id: string) => {
    setComponentHeightsMap((prev) => {
      const newHeights = { ...prev };
      delete newHeights[id];
      return newHeights;
    });
  }, []);

  // Get the height of a specific component by ID
  const getComponentHeightById = useCallback(
    (id: string): number => componentHeightsMap[id] || 0,
    [componentHeightsMap]
  );

  // Memoize the context value to avoid unnecessary renders
  const value = React.useMemo(
    () => ({
      registerComponentHeight,
      unregisterComponentHeight,
      getComponentHeightById, // Ajouté ici
      totalHeight,
    }),
    [
      registerComponentHeight,
      unregisterComponentHeight,
      getComponentHeightById,
      totalHeight,
    ]
  );

  return (
    <HeightManagerContext.Provider value={value}>
      {children}
    </HeightManagerContext.Provider>
  );
};

export const useHeightCalculator = (): HeightManagerContextProps => {
  const context = useContext(HeightManagerContext);
  if (!context) {
    throw new Error(
      "useHeightCalculator must be used within a HeightCalculatorProvider"
    );
  }
  return context;
};
