import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root";
import Contact from "./routes/Contact";
import Accueil from "./routes/Accueil";
import ErrorPage from "./error-page";
import Level from "./routes/Level";
import Blog from "./routes/Blog";
import "./index.css"; // Suppression de App.css si inutilisé
import LevelsMap from "./components/Map/LevelsMap";
import { MissionProvider } from "./context/MissionContext"; // Importation du MissionProvider
import { DrawerProvider } from "./context/DrawerContext";
import { ComponentHeightProvider } from "./context/HeightManagerContext";

console.log("App is starting...");

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Accueil />,
      },
      {
        path: "levelsMap",
        element: <LevelsMap />,
      },
      {
        path: "blog",
        element: <Blog />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "levels/:levelId",
        element: <Level />,
      },
      {
        path: "/error",
        element: <ErrorPage />, // Route spécifique pour les erreurs
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <MissionProvider>
    <DrawerProvider>
      <ComponentHeightProvider>
        <React.StrictMode>
          <RouterProvider router={router} />
        </React.StrictMode>
      </ComponentHeightProvider>
    </DrawerProvider>
  </MissionProvider>
);

reportWebVitals();
