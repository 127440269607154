import React, { useEffect, useState } from "react";
import { setLogCallback } from "../../services/logger";

const DebugLogs: React.FC = () => {
  const [logs, setLogs] = useState<string[]>([]);

  useEffect(() => {
    console.log("DebugLogs mounted");
    setLogCallback((message: string) => {
      setLogs((prevLogs) => [...prevLogs, message]);
    });

    return () => {
      console.log("DebugLogs unmounted");
      setLogCallback(null); // Nettoyage pour éviter les fuites
    };
  }, []);

  return (
    <div
      style={{
        position: "relative",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        color: "white",
        padding: "10px",
        fontSize: "12px",
        maxHeight: "150px",
        overflowY: "auto",
        zIndex: 9999,
      }}
    >
      {logs.map((log, index) => (
        <div key={index}>{log}</div>
      ))}
    </div>
  );
};

export default React.memo(DebugLogs); // Ajout de React.memo
