import React from "react";
import { useSearchParams } from "react-router-dom";
import DebugLogs from "./components/logging/DebugLogs";

export default function ErrorPage() {
  const [searchParams] = useSearchParams();
  const errorMessage =
    searchParams.get("message") || "An unexpected error occurred";

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{errorMessage}</i>
      </p>
      <DebugLogs /> {/* Affiche les logs ici */}
    </div>
  );
}
