import BottomNavigationWithDrawer from "../components/BottomNavigation/BottomNavigationWithDrawer";
import Footer from "../components/share/Footer";
import TopBar from "../components/share/TopBar";
import { Outlet, useLocation } from "react-router-dom";

export default function Root() {
  const location = useLocation(); // Récupère la route active

  const isLevelsMapActive = location.pathname === "/levelsMap";

  return (
    <div className="  bg-black text-white w-full min-h-screen relative overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-accent scrollbar-track-neutral ">
      {!isLevelsMapActive && <TopBar />}
      <Outlet />
      <div>
        <BottomNavigationWithDrawer />
      </div>
      {!isLevelsMapActive && <Footer />}
      {/* <DebugLogs /> */}
    </div>
  );
}
