import React, { ReactNode, useState } from "react";
import { motion, PanInfo } from "framer-motion";
import { useDrawerContext } from "../../context/DrawerContext";

interface DrawerProps {
  children: ReactNode;
  height?: number;
  openHeight?: number; // Nouvelle prop pour hauteur dynamique
}

const Drawer: React.FC<DrawerProps> = ({
  children,
  height = 300,
  openHeight = 300, // Par défaut
}) => {
  const { isOpen, setIsOpen } = useDrawerContext(); // Utilisation directe du context
  const [isDraggable, setIsDraggable] = useState(true);

  const isFirefox = () => {
    return (
      typeof navigator !== "undefined" && /firefox/i.test(navigator.userAgent)
    );
  };

  const handleDragEnd = (
    e: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) => {
    const closingThreshold = height * 0.9; // Ajusté avec la hauteur
    setIsOpen(info.point.y <= closingThreshold);
  };

  return (
    <div className="relative">
      <motion.div
        className="fixed bottom-0 left-0 right-0"
        style={{ height }}
        drag={isDraggable ? "y" : false}
        dragConstraints={{ top: 0, bottom: height }}
        onPointerDown={(e) => {
          const target = e.target as HTMLElement;
          if (target.tagName === "SELECT" && isFirefox()) {
            setIsDraggable(false);
          }
        }}
        onDragStart={(e) => {
          const target = e.target as HTMLElement;
          if (target.tagName === "SELECT" && isFirefox()) {
            setIsDraggable(true);
          }
        }}
        onDragEnd={handleDragEnd}
        animate={{ y: isOpen ? height - openHeight : height }}
        transition={{
          type: "spring",
          stiffness: 400,
          damping: 80,
          mass: 1,
        }}
      >
        <div className="overflow-hidden" style={{ height: height - 60 }}>
          {children}
        </div>
      </motion.div>
    </div>
  );
};

export default Drawer;
