let logCallback: ((message: string) => void) | null = null;

export const setLogCallback = (
  callback: ((message: string) => void) | null
) => {
  logCallback = callback;
};

export const logMessage = (message: string) => {
  console.log(message); // Écrit dans la console
  if (logCallback) {
    logCallback(message); // Transmet au composant DebugLogs
  }
};
