import React, { createContext, useContext, useState } from "react";

type DrawerTrigger = "button" | "marker" | null;

interface DrawerContextProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  drawerTrigger: DrawerTrigger;
  setDrawerTrigger: React.Dispatch<React.SetStateAction<DrawerTrigger>>;
}

const DrawerContext = createContext<DrawerContextProps | undefined>(undefined);

interface DrawerContextProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  drawerTrigger: DrawerTrigger;
  setDrawerTrigger: React.Dispatch<React.SetStateAction<DrawerTrigger>>;
  currentCurrentLevelId: string | null; // Ajout de l'ID actif
  setCurrentCurrentLevelId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const DrawerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [drawerTrigger, setDrawerTrigger] = useState<DrawerTrigger>(null);
  const [currentLevelId, setCurrentLevelId] = useState<string | null>(null); // État pour la carte active

  return (
    <DrawerContext.Provider
      value={{
        isOpen,
        setIsOpen,
        drawerTrigger,
        setDrawerTrigger,
        currentCurrentLevelId: currentLevelId,
        setCurrentCurrentLevelId: setCurrentLevelId,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawerContext = (): DrawerContextProps => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error("useDrawerContext must be used within a DrawerProvider");
  }
  return context;
};
