import { useEffect, useRef, useState } from "react";
import { useHeightCalculator } from "../context/HeightManagerContext";

/**
 * useRegisterHeight
 *
 * Ce hook permet de mesurer et d'enregistrer dynamiquement la hauteur d'un composant
 * dans le contexte de gestion des hauteurs (HeightCalculatorContext).
 *
 * @param {string} id - Type de l'element html du composant dont la hauteur est enregistrée.
 * @param {boolean} isActive - Indique si le composant est actuellement actif :
 *    - true : Le composant est actif, sa hauteur sera mesurée et enregistrée.
 *    - false : Le composant est inactif, sa hauteur ne sera pas mesurée ni enregistrée.
 *
 * Rôle de isActive :
 * - Optimise les performances en évitant de mesurer et d'enregistrer les hauteurs de composants inactifs.
 * - Permet de contrôler dynamiquement quels composants contribuent à la hauteur totale.
 */

const useRegisterHeight = (
  id: string,
  isActive: boolean // Ajout d'une option isActive
) => {
  const {
    registerComponentHeight: addHeight,
    unregisterComponentHeight: removeHeight,
  } = useHeightCalculator();
  const [height, setHeight] = useState<number | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const zoomFactor = window.devicePixelRatio;
      console.log("Current Zoom Level:", zoomFactor);
    });
    if (isActive && ref.current) {
      // Actif uniquement si isActive est vrai
      const measureHeight = () => {
        if (!ref.current) return;

        const element = ref.current;
        const rect = element.getBoundingClientRect(); // Inclut padding + border
        const styles = window.getComputedStyle(element);
        const margin =
          parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);

        const correctedHeight =
          (rect.height + margin) / window.devicePixelRatio;

        if (correctedHeight !== height) {
          setHeight(correctedHeight);
        }
      };

      measureHeight();

      window.addEventListener("resize", measureHeight);
      return () => window.removeEventListener("resize", measureHeight);
    }
  }, [isActive, height]);

  useEffect(() => {
    if (isActive && height !== null) {
      // Enregistrer seulement si isActive
      addHeight(id, height);
    }

    return () => {
      if (isActive) removeHeight(id);
    };
  }, [id, height, addHeight, removeHeight, isActive]);

  return ref;
};

export default useRegisterHeight;
