import TitleSection from "../components/titleSection/TitleSection";
import { useMissionContext } from "../context/MissionContext";
import PresentationImage from "../components/PresentationImage";

const Accueil = () => {
  useMissionContext();

  return (
    <div className="max-w-full h-screen pt-20 pb-20 px-4">
      <TitleSection />
      <PresentationImage />
    </div>
  );
};

export default Accueil;
