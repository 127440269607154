import React from "react";
import { useMissionContext } from "../../context/MissionContext";
import MissionList from "../MissionList";
import Drawer from "../Drawer/Drawer";
import BottomNavigation from "./BottomNavigation";
import SearchForm from "../SearchForm";
import { useHeightCalculator } from "../../context/HeightManagerContext";
import useDeviceDetection from "../../hooks/useDeviceDetection";

const BottomNavigationWithDrawer: React.FC = () => {
  const { filteredMissions, filterMissions, countries, types } =
    useMissionContext();
  const { totalHeight } = useHeightCalculator();
  const { isMobile, isTablet, viewportHeight } = useDeviceDetection();

  const openHeight =
    isMobile || isTablet ? (viewportHeight * 2) / 3 : totalHeight;

  return (
    <div className="relative bg-gray-100 z-40">
      <Drawer height={viewportHeight} openHeight={openHeight}>
        <div className="sticky top-0 z-10">
          <SearchForm
            onFilterChange={filterMissions}
            countries={countries.map((country) => country.name)}
            types={types.map((type) => type.name)}
          />
        </div>
        <MissionList missions={filteredMissions} />{" "}
      </Drawer>

      <div className="fixed bottom-0 left-0 right-0 z-50">
        <BottomNavigation />
      </div>
    </div>
  );
};

export default BottomNavigationWithDrawer;
