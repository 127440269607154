import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDrawerContext } from "../../context/DrawerContext";
import useRegisterHeight from "../../hooks/useRegisterHeight";

const BottomNavigation: React.FC = () => {
  const {
    isOpen,
    setIsOpen,
    setDrawerTrigger,
    setCurrentCurrentLevelId: setCurrentLevelId,
  } = useDrawerContext(); // Utilisation directe du context

  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRegisterHeight("bottomNavigation", true); // Auto-mesure activée

  const toggleDrawer = () => {
    setDrawerTrigger("button");
    setIsOpen((prev) => !prev);
    setCurrentLevelId("NONE"); // Définit l'ID spécial
  };

  const handleClick = (path: string, toggle: boolean) => {
    if (location.pathname === "/levelsMap") {
      if (path !== "/levelsMap" && isOpen) {
        toggleDrawer();
      } else if (path === "/levelsMap" && toggle) {
        toggleDrawer();
      }
    } else {
      if (path === "/levelsMap" && !isOpen) {
        toggleDrawer();
      }
    }

    navigate(path);
  };

  const isActive = (path: string) => location.pathname === path;

  return (
    <div
      ref={ref}
      className="h-20 btm-nav bg-gray-800 text-white flex justify-around items-center"
    >
      <button
        onClick={() => handleClick("/", true)}
        className={`text-4xl ${
          isActive("/")
            ? " bg-primary filter brightness-125"
            : "bg-primary filter brightness-50"
        }`}
      >
        <span className="btm-nav-label">HOME</span>
      </button>
      <button
        onClick={() => handleClick("/levelsMap", true)}
        className={`text-4xl ${
          isActive("/levelsMap")
            ? " bg-secondary filter brightness-125"
            : "bg-secondary filter brightness-50"
        }`}
      >
        <span className="btm-nav-label">MISSIONS</span>
      </button>
      <button
        className={`text-4xl ${
          isActive("/prochain_truc")
            ? " bg-accent filter brightness-125"
            : "bg-accent filter brightness-50"
        }`}
      >
        <span className="btm-nav-label">CURRENT MISSION</span>
      </button>
    </div>
  );
};

export default React.memo(BottomNavigation);
